
import './App.css';
import Drawing from './components/Drawing';
function App() {
  return (
    <div>
   
    <Drawing/>
  </div>
  );
}

export default App;
