// DrawingCanvas.js
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const DrawingCanvas = () => {
  const sigCanvas = useRef({});

  const clear = () => {
    sigCanvas.current.clear();
  };

  return (
    <div style={{ textAlign: 'center' }}>
     
      <div style={{ 
        border: '2px solid #000', 
        width: 'fit-content', 
        margin: 'auto',
        padding: '10px',
        display: 'inline-block'
      }}>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{ width: 800, height: 400, className: 'sigCanvas' }}
        />
      </div>
      <button onClick={clear} style={{ marginTop: '10px' }}>Clear</button>
    </div>
  );
};

export default DrawingCanvas;
